import React from "react";
import "./App.css"; // Stil dosyası

const App = () => {
  return (
    <div className="app">
      <h1>
        <span>Just</span> Empty!
      </h1>
      <h2>Come Back Later</h2>
    </div>
  );
};

export default App;
